 class ZIDXFormField {
    name="";
    label="";
    help="";
    placeholder="";
    helpSuffix="";
    html="";
    custom="";
    values:ZIDXFormFieldValue[]=[]
    type:ZIDXFormFieldType=ZIDXFormFieldType.TEXT;
    required=false;
    multiple=false;
}